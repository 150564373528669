import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "delete-a-user"
    }}>{`Delete a User`}</h1>
    <hr></hr>
    <p>{`To delete a user from the Content Management System, follow these step-by-step instructions.`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and click on Users from the left side menu options.`}</li>
      <li parentName="ol">{`Select 'Delete' next to the user you would like to remove from the system.`}</li>
    </ol>
    <p><img alt="CMS Delete User" src={require("./images/delete_user.png")} />{` `}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Confirm that you would like to delete this account by selecting 'Delete'. `}<em parentName="li">{`All content created by this user will also be deleted from the system.`}</em>{` `}</li>
      <li parentName="ol">{`If you don't wish to delete the user account, select 'Cancel'.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      